import { useRouter } from 'next/router';
import Link from 'next/link';

import MuiContainer from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import Logo from 'lib/smava/Logo';
import { isSEAPage } from 'lib/query-params/helper';

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 0;
`;

const StyledLogo = styled(Logo)`
  width: 100px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 115px;
  }
`;

const LogoBarContainer = styled(MuiContainer)`
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) => props.theme.breakpoints.up('sm')} {
    height: 58px;
  }
`;

const BoxShadow = styled(Paper)`
  margin-bottom: 0.2em;
  border-radius: 0;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
`;

/**
 * Renders the Header Component. The right side content is changing according the context, so it can be supplied via props,
 * same goes for the content for the bottom in case you need to squeeze some more content into the header section like
 * a nav bar for example.
 */
function Header({
  contentRight,
  contentBottom,
}: {
  contentRight?: React.ReactNode;
  contentBottom?: React.ReactNode;
}) {
  const router = useRouter();
  const hideBackLink = isSEAPage(router.pathname);
  return (
    <header>
      <BoxShadow>
        <LogoBarContainer>
          {hideBackLink ? (
            <StyledLogo />
          ) : (
            <LogoLink
              passHref
              href={{ pathname: '/', query: router.query }}
              aria-label="smava - Ihr Kredit ist da."
            >
              <StyledLogo />
            </LogoLink>
          )}
          <Box display="flex">{contentRight}</Box>
        </LogoBarContainer>
      </BoxShadow>
      {contentBottom}
    </header>
  );
}

export default Header;
